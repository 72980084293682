import ErrorPage from '@/components/ErrorPage/ErrorPage';
import EmptyLayout from '@/components/layout/empty/EmptyLayout';

import type {ReactElement} from 'react';

const Custom404 = () => <ErrorPage />;

Custom404.getLayout = function getLayout(page: ReactElement) {
  return <EmptyLayout pageTitle={`Oops, Something's Not Right! | Ka. Web App`}>{page}</EmptyLayout>;
};

export default Custom404;
